import { UIKitTheme } from '@urbaninfrastructure/react-ui-kit';

export const DOCK_DOTS_LAYER_ID = 'dock-dots';
export const DOCK_GROUP_DOTS_SOURCE_ID = 'dock-group-dots-source';
export const DOTS_TEXT_ID = 'dock-dots-text';

export function getDockDots(
  theme: UIKitTheme,
  dotCircleColor: mapboxgl.Expression
) {
  const dockDots: mapboxgl.AnyLayer = {
    id: DOCK_DOTS_LAYER_ID,
    source: DOCK_GROUP_DOTS_SOURCE_ID,
    type: 'circle',
    paint: {
      'circle-color': [
        'case',
        ['==', ['get', 'enabled'], true],
        dotCircleColor,
        theme.colors.neutral[4]
      ],
      'circle-radius': {
        stops: [
          [8, 1],
          [10, 2],
          [12, 6],
          [16, 12]
        ]
      }
    }
  };
  return dockDots;
}

export function getDockDotsText(
  primaryProp: string,
  paintTextColor: mapboxgl.Expression
) {
  const text: mapboxgl.AnyLayer = {
    id: DOTS_TEXT_ID,
    source: DOCK_GROUP_DOTS_SOURCE_ID,
    type: 'symbol',
    layout: {
      'text-field': [
        'case',
        ['==', ['get', 'enabled'], true],
        ['get', primaryProp],
        '-'
      ],
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': {
        stops: [
          [8, 0],
          [10, 0],
          [11, 0],
          [12, 7],
          [16, 12]
        ]
      },
      'text-allow-overlap': true
    },
    paint: {
      'text-color': paintTextColor
    }
  };
  return text;
}
