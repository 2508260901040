import * as React from 'react';

const InurbaHelsinkiLogo = ({
  width = 100,
  height = 100,
  alt = 'Helsinki'
}: React.ImgHTMLAttributes<HTMLImageElement>) => {
  return (
    <img
      width={width}
      height={height}
      src="/static/assets/inurba-helsinki-logo.svg"
      alt={alt}
    />
  );
};

export default InurbaHelsinkiLogo;
