import { RouteConfig } from './types';

export const routeConfig: RouteConfig = {
  home: {
    page: 'index'
  },
  'intercom-app': {
    slug: {
      en: 'intercom-app',
      nb: 'intercom-app',
      it: 'intercom-app',
      fr: 'intercom-app',
      de: 'intercom-app',
      pl: 'intercom-app',
      uk: 'intercom-app',
      sv: 'intercom-app',
      es: 'intercom-app',
      fi: 'intercom-app'
    }
  },
  buy: {
    slug: {
      en: 'buy',
      it: 'compra',
      nb: 'kjop',
      fr: 'acheter',
      de: 'kaufen',
      pl: 'kup',
      uk: 'prydbaty',
      sv: 'kopa',
      es: 'compra',
      fi: 'osta'
    },
    params: '/:productSlug?'
  },
  'buy-gift-card': {
    slug: {
      en: 'buy-gift-card',
      it: 'acquista-una-gift-card',
      nb: 'kjop-gavekort',
      fr: 'acheter-carte-cadeau',
      de: 'kaufen-geschenkkarte',
      pl: 'kup-karte-podarunkowa',
      uk: 'prydbaty-podarunkovu-kartku',
      sv: 'kopa-presentkort',
      es: 'compra-tarjeta-de-regalo',
      fi: 'osta-lahjakortti'
    },
    params: '/:productSlug?'
  },
  'log-in': {
    slug: {
      en: 'log-in',
      it: 'login',
      nb: 'logg-inn',
      fr: 'connexion',
      de: 'anmelden',
      pl: 'zaloguj-sie',
      uk: 'vhid',
      sv: 'logga-in',
      es: 'iniciar-sesion',
      fi: 'kirjaudu-sisaan'
    }
  },
  profile: {
    slug: {
      en: 'profile',
      it: 'profilo',
      nb: 'profil',
      fr: 'profil',
      de: 'profil',
      pl: 'profil',
      uk: 'profil',
      sv: 'profil',
      es: 'perfil',
      fi: 'profiili'
    },
    params: '/:subSlug?',
    protected: true,
    children: {
      'personal-info': {
        slug: {
          en: 'personal-info',
          it: 'info-personali',
          nb: 'personlig-info',
          fr: 'personnel-info',
          de: 'personliche-info',
          pl: 'informacje-osobiste',
          uk: 'osobysta-informatsiya',
          sv: 'personlig-info',
          es: 'informacion-personal',
          fi: 'henkilokohtaiset-tiedot'
        }
      },
      'assign-rfid-card': {
        slug: {
          en: 'assign-rfid-card',
          it: 'assign-rfid-card',
          nb: 'assign-rfid-card',
          fr: 'assign-rfid-card',
          de: 'assign-rfid-card',
          pl: 'assign-rfid-card',
          uk: 'assign-rfid-card',
          sv: 'assign-rfid-card',
          es: 'assign-rfid-card',
          fi: 'assign-rfid-card'
        }
      },
      trips: {
        slug: {
          en: 'trips',
          it: 'utilizzi',
          nb: 'turer',
          fr: 'trajets',
          de: 'reisen',
          pl: 'podroze',
          uk: 'podorozhi',
          sv: 'resor',
          es: 'viajes',
          fi: 'matkat'
        }
      },
      receipts: {
        slug: {
          en: 'receipts',
          it: 'ricevute',
          nb: 'kvitteringer',
          fr: 'recus',
          de: 'quittungen',
          pl: 'paragony',
          uk: 'paragony',
          sv: 'kvitton',
          es: 'recibos',
          fi: 'kuitti'
        }
      },
      invoices: {
        slug: {
          en: 'invoices',
          it: 'fatture',
          nb: 'fakturaer',
          fr: 'factures',
          de: 'rechnungen',
          pl: 'faktury',
          uk: 'rakhunki',
          sv: 'fakturor',
          es: 'facturas',
          fi: 'laskut'
        }
      },
      'credit-cards': {
        slug: {
          en: 'credit-cards',
          it: 'carte-di-credito',
          nb: 'kredittkort',
          fr: 'cartes-de-credit',
          de: 'kreditkarten',
          pl: 'karty-kredytowe',
          uk: 'kredytni-karty',
          sv: 'kreditkort',
          es: 'tarjetas-de-credito',
          fi: 'luottokortit'
        },
        params: '/:paymentId?'
      },
      wallet: {
        slug: {
          en: 'wallet',
          it: 'portafoglio',
          nb: 'lommebok',
          fr: 'portefeuille',
          de: 'brieftasche',
          pl: 'portfel',
          uk: 'gamanets',
          sv: 'planbok',
          es: 'billetera',
          fi: 'lompakko'
        }
      },
      'pin-code': {
        slug: {
          en: 'pin-code',
          it: 'codice-pin',
          nb: 'pinkode',
          fr: 'code-pin',
          de: 'pin-code',
          pl: 'kod-pin',
          uk: 'pin-kod',
          sv: 'pinkod',
          es: 'codigo-pin',
          fi: 'pin-koodi'
        }
      },
      'your-data': {
        slug: {
          en: 'your-data',
          it: 'tuoi-dati',
          nb: 'dine-data',
          fr: 'vos-donnees',
          de: 'deine-daten',
          pl: 'twoje-dane',
          uk: 'vashi-dani',
          sv: 'dina-data',
          es: 'tus-datos',
          fi: 'tietosi'
        }
      }
    }
  },
  receipt: {
    slug: {
      en: 'receipt',
      it: 'ricevuta',
      nb: 'kvittering',
      fr: 'le-recu',
      de: 'quittung',
      pl: 'paragon',
      uk: 'paragon',
      sv: 'kvitto',
      es: 'recibo',
      fi: 'kuitti'
    },
    params: '/:token'
  },
  invoices: {
    slug: {
      en: 'invoices',
      it: 'fatture',
      nb: 'fakturaer',
      fr: 'factures',
      de: 'rechnungen',
      pl: 'faktury',
      uk: 'rakhunky',
      sv: 'fakturor',
      es: 'facturas',
      fi: 'laskut'
    },
    params: '/:token',
    children: {
      plain: {
        slug: {
          en: 'plain',
          it: 'pianura',
          nb: 'vanlig',
          fr: 'plaine',
          de: 'ebene',
          pl: 'zwykly',
          uk: 'prostyi',
          sv: 'vanlig',
          es: 'liso',
          fi: 'tasainen'
        }
      }
    }
  },
  'station-map': {
    slug: {
      en: 'stations',
      it: 'stazioni',
      nb: 'stasjoner',
      fr: 'stations',
      de: 'stationen',
      pl: 'stacje',
      uk: 'stanzii',
      sv: 'stationer',
      es: 'estaciones',
      fi: 'asemat'
    },
    params: '/:view?'
  },
  'redeem-coupon': {
    slug: {
      en: 'redeem-giftcard',
      it: 'riscatta-giftcard',
      nb: 'los-inn-gavekort',
      fr: 'utiliser-carte-cadeau',
      de: 'einlosen-geschenkkarte',
      pl: 'wykorzystaj-karte-podarunkowa',
      uk: 'vykorystay-podarunkovu-kartku',
      sv: 'los-inn-presentkort',
      es: 'canjear-tarjeta-de-regalo',
      fi: 'lunasta-lahjakortti'
    }
  },
  'order-confirmation': {
    slug: {
      en: 'order-confirmation',
      it: 'confirmazione-ordine',
      nb: 'ordrebekreftelse',
      fr: 'confirmation-de-commande',
      de: 'bestellbestatigung',
      pl: 'potwierdzenie-zamowienia',
      uk: 'pidtverdzhennya-zamovlennya',
      sv: 'orderbekraftelse',
      es: 'confirmacion-de-pedido',
      fi: 'tilausvahvistus'
    },
    params: '/:token'
  },
  giftcard: {
    slug: {
      en: 'giftcard',
      it: 'giftcard',
      nb: 'gavekort',
      fr: 'carte-cadeau',
      de: 'geschenkkarte',
      pl: 'karta-podarunkowa',
      uk: 'podarunkova-kartka',
      sv: 'presentkort',
      es: 'tarjeta-de-regalo',
      fi: 'lahjakortti'
    },
    params: '/:token'
  },
  'how-it-works': {
    slug: {
      en: 'how-it-works',
      it: 'come-funziona',
      nb: 'slik-virker-det',
      fr: 'comment-ca-marche',
      de: 'wie-es-funktioniert',
      pl: 'jak-to-dziala',
      uk: 'yak-tse-pratsyuye',
      sv: 'sa-fungerar-det',
      es: 'como-funciona',
      fi: 'kuinka-se-toimii'
    }
  },
  'open-data': {
    slug: {
      en: 'open-data',
      it: 'dati-aperti',
      nb: 'apne-data',
      fr: 'open-data',
      de: 'offene-daten',
      pl: 'otwarte-dane',
      uk: 'vidkryti-dani',
      sv: 'oppna-data',
      es: 'datos-abiertos',
      fi: 'avoimet-tiedot'
    },
    params: '/:subSlug?',
    children: {
      realtime: {
        slug: {
          en: 'realtime',
          it: 'tempo-reale',
          nb: 'sanntid',
          fr: 'donnees-ouvertes',
          de: 'echtzeit',
          pl: 'dane-otwarte',
          uk: 'realny-chas',
          sv: 'realtid',
          es: 'tiempo-real',
          fi: 'realtieto'
        }
      },
      historical: {
        slug: {
          en: 'historical',
          it: 'storico',
          nb: 'historisk',
          fr: 'historique',
          de: 'historisch',
          pl: 'historyczne',
          uk: 'istorychni',
          sv: 'historisk',
          es: 'historico',
          fi: 'historiallinen'
        }
      },
      app: {
        slug: {
          en: 'app-integration',
          it: 'integrazione-app',
          nb: 'app-integrasjon',
          fr: 'app-integration',
          de: 'app-integration',
          pl: 'integracja-aplikacji',
          uk: 'intehratsiya-dodatku',
          sv: 'app-integration',
          es: 'integracion-de-aplicaciones',
          fi: 'sovellusintegraatio'
        }
      }
    }
  },
  'system-status': {
    slug: {
      en: 'system-status',
      it: 'stato-sistema',
      nb: 'system-status',
      fr: 'etat-du-systeme',
      de: 'systemstatus',
      pl: 'status-systemu',
      uk: 'stan-systemy',
      sv: 'systemstatus',
      es: 'estado-del-sistema',
      fi: 'jarjestelman-tila'
    },
    children: {
      status: {
        slug: {
          en: 'status',
          it: 'stato',
          nb: 'status',
          fr: 'status',
          de: 'status',
          pl: 'status',
          uk: 'stan',
          sv: 'status',
          es: 'estado',
          fi: 'tila'
        },
        params: '/:subSlug/:statusId?'
      }
    }
  },
  app: {
    slug: {
      en: 'app',
      it: 'app',
      nb: 'app',
      fr: 'app',
      de: 'app',
      pl: 'app',
      uk: 'app',
      sv: 'app',
      es: 'app',
      fi: 'app'
    },
    page: 'app-landing'
  },
  onboarding: {
    slug: {
      en: 'onboarding',
      it: 'onboarding',
      nb: 'registrering',
      fr: 'onboarding',
      de: 'registrierung',
      pl: 'rejestracja',
      uk: 'regystratsiya',
      sv: 'registrering',
      es: 'registro',
      fi: 'rekisterointi'
    }
  },
  business: {
    slug: {
      en: 'business',
      it: 'azienda',
      nb: 'bedrift',
      fr: 'entreprise',
      de: 'unternehmen',
      pl: 'firma',
      uk: 'pidpryyemstvo',
      sv: 'foretag',
      es: 'empresa',
      fi: 'yritys'
    }
  },
  email: {
    slug: {
      en: 'email',
      it: 'email',
      nb: 'email',
      fr: 'email',
      de: 'email',
      pl: 'email',
      uk: 'email',
      sv: 'email',
      es: 'email',
      fi: 'email'
    },
    params: '/:subSlug?',
    children: {
      business_register_welcome: {
        slug: {
          en: 'business_register_welcome',
          it: 'business_register_welcome',
          nb: 'business_register_welcome',
          fr: 'business_register_welcome',
          de: 'business_register_welcome',
          pl: 'business_register_welcome',
          uk: 'business_register_welcome',
          sv: 'business_register_welcome',
          es: 'business_register_welcome',
          fi: 'business_register_welcome'
        }
      }
    }
  },
  // 'privacy-policy': {
  //   slug: {
  //     en: 'privacy-policy',
  //     it: 'informativa-sulla-privacy',
  //     nb: 'personvernerklaering',
  //     fr: 'politique-de-confidentialite',
  //     de: 'datenschutzerklarung',
  //     pl: 'polityka-prywatnosci',
  //     uk: 'polityka-konfidencijalnosti',
  //     sv: 'integritetspolicy',
  //     es: 'politica-de-privacidad',
  //     fi: 'tietosuojakaytanto'
  //   }
  // },
  'terms-of-use': {
    slug: {
      en: 'terms-of-use',
      nb: 'vilkaar-for-bruk',
      it: 'termini-e-condizioni',
      fr: 'conditions-dutilisation',
      de: 'nutzungsbedingungen',
      pl: 'warunki-korzystania',
      uk: 'umovy-korystuvannya',
      sv: 'anvandarvillkor',
      es: 'terminos-y-condiciones',
      fi: 'kayttoehdot'
    }
  },
  'terms-of-purchase': {
    slug: {
      en: 'terms-of-purchase',
      nb: 'vilkaar-for-kjop',
      it: 'termini-di-acquisto',
      fr: 'conditions-dachat',
      de: 'kaufbedingungen',
      pl: 'warunki-zakupu',
      uk: 'umovy-kupivli',
      sv: 'kopsvillkor',
      es: 'terminos-de-compra',
      fi: 'ostoehtoja'
    }
  },
  'email-confirmation': {
    slug: {
      en: 'email-confirmation',
      it: 'conferma-via-e-mail',
      nb: 'e-postbekreftelse',
      fr: 'confirmation-par-courriel',
      de: 'e-mail-bestatigung',
      pl: 'potwierdzenie-e-mail',
      uk: 'pidtverdzhennya-e-mail',
      sv: 'e-post-bekraftelse',
      es: 'email-de-confirmacion',
      fi: 'sahkopostivahvistus'
    },
    params: '/:token'
  }
};

export default routeConfig;
