import { CustomFormats, IntlShape, useIntl } from 'react-intl';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInHours from 'date-fns/differenceInHours';
import addMinutes from 'date-fns/addMinutes';
import { SystemConfig } from './types';
import { NextRouter } from 'next/router';
import { durationMessages } from '../components/common-i18n';
import { staticDataQuery_system } from '../core-types';

// format phone numbers
export function formatPhoneNumber(prefix: string, phoneNumber: string): string {
  switch (prefix) {
    case '+47': {
      return (
        phoneNumber.substr(0, 3) +
        ' ' +
        phoneNumber.substr(3, 2) +
        ' ' +
        phoneNumber.substr(5, 3)
      );
    }
    default: {
      return phoneNumber;
    }
  }
}

export function isAppContent(query: { app?: string }): boolean {
  return !!query.app;
}

export function isBikeMi(systemConfig: SystemConfig): boolean {
  return systemConfig.id === 'milan-bikemi';
}

export function isVeronaBike(systemConfig: SystemConfig): boolean {
  return systemConfig.id === 'verona-bike';
}

export function isInurbaRouen(systemConfig: SystemConfig): boolean {
  return systemConfig.id === 'inurba-rouen';
}

export function isInurbaGdansk(systemConfig: SystemConfig): boolean {
  return systemConfig.id === 'inurba-gdansk';
}

export function isLondonGreenquarter(systemConfig: SystemConfig): boolean {
  return systemConfig.id === 'london-greenquarter';
}

export function isInurbaHelsinki(systemConfig: SystemConfig): boolean {
  return systemConfig.id === 'helsinki-citybike';
}

export function getFaviconKey(
  systemConfig: SystemConfig,
  staticDataSystem: staticDataQuery_system
): string | undefined {
  return systemConfig.faviconKey || staticDataSystem.colourKey || undefined;
}

export function isPreviewMode(
  systemConfig: SystemConfig,
  url: NextRouter
): boolean {
  if (url.query.__previewMode) {
    return url.query.__previewMode !== '0';
  }
  return !!systemConfig.featureFlags.previewMode;
}

// example url: https://itunes.apple.com/us/app/feliz-navidapp-felicita-la/id771141588?mt=8
export function extractIOSAppId(url: string | null): string | undefined {
  if (url) {
    const match = url.match(/id([\d]{9,})/);
    if (match && match[1]) {
      return match[1];
    }
  }
}

export const isServer: boolean = typeof window === 'undefined';

const pad = (n: number): string => (n < 10 ? `0${n}` : `${n}`);

export function secondsToMMSS(
  input: number | string,
  padMinutes = true,
  showSecondsIfZero = true
): string {
  const secNum = Math.floor(
    typeof input === 'string' ? parseInt(input, 10) : input
  );
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor((secNum - hours * 3600) / 60);
  const seconds = secNum - hours * 3600 - minutes * 60;
  const formattedMinutes = padMinutes ? pad(minutes) : minutes;
  if (input === 3600) {
    if (!showSecondsIfZero) {
      return '60';
    }
    return '60:00';
  }
  if (!showSecondsIfZero && !seconds) {
    return `${formattedMinutes}`;
  }
  return `${formattedMinutes}:${pad(seconds)}`;
}

export function getFormats(currency: string | undefined): CustomFormats {
  return {
    date: {
      'year-only': {
        year: 'numeric'
      },
      date: {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      },
      'date-time': {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour12: false,
        hour: 'numeric',
        minute: 'numeric'
      }
    },
    number: {
      currency: {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }
    }
  };
}

export function isNil(val: any): boolean {
  return val === undefined || val === null;
}

export function formatDuration({
  intl,
  durationMins
}: {
  intl: IntlShape;
  durationMins: number;
}): string {
  const showSeconds = durationMins * 60 < 60;
  const showMinutes = durationMins < 60;
  const showHours = durationMins <= 60 * 72;

  if (showSeconds) {
    return intl.formatMessage(durationMessages.seconds, {
      seconds: Math.round(durationMins * 60)
    });
  } else if (showMinutes) {
    return intl.formatMessage(durationMessages.minutes, {
      minutes: Math.round(durationMins)
    });
  } else if (showHours) {
    const now = new Date();
    const hours = differenceInHours(addMinutes(now, durationMins), now);
    return intl.formatMessage(durationMessages.hours, {
      hours
    });
  } else {
    const now = new Date();
    const days = differenceInDays(addMinutes(now, durationMins), now);
    return intl.formatMessage(durationMessages.days, {
      days
    });
  }
}

export function FormattedDuration({ durationMins }: { durationMins: number }) {
  const intl = useIntl();
  return <>{formatDuration({ intl, durationMins })}</>;
}

export function clearSessionStorage() {
  if (isServer) {
    return;
  }
  if (!sessionStorage) {
    return;
  }
  sessionStorage.clear();
}
