import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Feature } from 'geojson';
import { Text, Box, useMapContext, Flex, Anchor } from '../../';
import { DockGroupState } from '../../../core-types';
import { getRouteUrl } from '../../../src/utils/route';
import { AvailabilityInfo } from './AvailabilityInfo';

export interface DotInfoProps {
  superpowers: boolean;
  dot?: Feature;
}

export const DotInfo: FC<DotInfoProps> = props => {
  const { superpowers, dot } = props;

  const { activeDotFeature } = useMapContext();

  const href =
    (activeDotFeature?.geometry.type === 'Point' &&
      getRouteUrl(
        activeDotFeature?.geometry.coordinates[1],
        activeDotFeature?.geometry.coordinates[0]
      )) ||
    undefined;

  const dotToShow = dot || activeDotFeature;
  if (!dotToShow) return null;

  const title =
    dotToShow?.properties?.title ||
    dotToShow?.properties?.name ||
    dotToShow?.properties?.id;
  const stationIsNotInServiceOrMaintenance =
    dotToShow?.properties?.state === DockGroupState.not_in_service ||
    dotToShow?.properties?.state === DockGroupState.maintenance;

  const rangeInKilometers = dotToShow?.properties?.currentRange / 1000;

  return (
    <Box
      bg="white"
      position="relative"
      p={3}
      fontFamily="sansSerif"
      textAlign="center"
    >
      {superpowers && (
        <Box position="absolute" left={0} top={0}>
          💪
        </Box>
      )}
      <Text fontSize={2} mb="xxs">
        {title}
      </Text>
      {dotToShow.properties?.subTitle && (
        <Text mute fontSize={1}>
          {dotToShow.properties?.subTitle}
        </Text>
      )}
      {/* {!dotToShow.properties?.enabled && (
        <Text mt={2} typoStyle="xxs">
          <FormattedMessage
            id="station_is_unavailable"
            defaultMessage="Station is unavailable"
          />
        </Text>
      )} */}
      {dotToShow.properties?.currentRange && (
        <Flex>
          <Text mute textAlign="center">
            <FormattedMessage
              id="modules.StationMap.range"
              defaultMessage="Range"
            />
          </Text>

          <Text mute>
            : {rangeInKilometers <= 1 ? '<1' : '~ ' + rangeInKilometers} km
          </Text>
        </Flex>
      )}

      {dotToShow.properties?.batteryCharge && (
        <Flex>
          <Text mute textAlign="center">
            <FormattedMessage
              id="battery_level_content_description"
              defaultMessage="Battery level"
            />
          </Text>

          <Text mute>: {dotToShow.properties?.batteryCharge}%</Text>
        </Flex>
      )}
      {superpowers && (
        <>
          {stationIsNotInServiceOrMaintenance && (
            <Text typoStyle="xxs">
              {dotToShow.properties?.state ===
                DockGroupState.not_in_service && (
                <FormattedMessage
                  id="dockGroup_not_in_service"
                  defaultMessage="Not in service"
                />
              )}
              {dotToShow.properties?.state === DockGroupState.maintenance && (
                <FormattedMessage
                  id="dockGroup_maintenance"
                  defaultMessage="Maintenance"
                />
              )}
            </Text>
          )}
        </>
      )}
      <AvailabilityInfo dot={dotToShow} superpowers={superpowers} />
      <Box textAlign="center" mt={2}>
        <Anchor
          href={href}
          target="_blank"
          rel="noreferrer"
          style={{ borderBottom: 'none' }}
        >
          <FormattedMessage
            id="modules.StationMap.showRoute"
            defaultMessage="Show route"
          />
        </Anchor>
      </Box>
      {dotToShow?.properties?.state === DockGroupState.maintenance ? (
        <Box textAlign="center" mt={3}>
          <FormattedMessage
            id="modules.StationMap.stationUnderMaintenance"
            defaultMessage="The station is under maintenance."
          />
        </Box>
      ) : (
        !dotToShow?.properties?.enabled && (
          <Box textAlign="center" mt={3}>
            <FormattedMessage
              id="modules.StationMap.stationClosed"
              defaultMessage="The station is closed."
            />
          </Box>
        )
      )}
    </Box>
  );
};
